<template lang="pug">
q-page(
  v-on:keydown.meta.83.prevent.stop="save",
  v-on:keydown.ctrl.83.prevent.stop="save"
)
  portal(to="header_buttons", v-if="id")
    q-btn(
      v-if="type=='templates'"
      rounded,
      flat,
      dense,
      icon="arrow_back_ios_new",
      color="primary",
      to="/editor/templates",
      label="All Templates"
    )
    q-btn(
      v-if="type=='styles'"
      rounded,
      flat,
      dense,
      icon="arrow_back_ios_new",
      color="primary",
      to="/editor/styles",
      label="All Styles"
    )
  .column.justify-start.absolute-full
    .col.column
      .row.fit
        .col(style="height: 100%")
          .column(style="height: 100%")
            .col-auto
              q-toolbar
                q-toolbar-title.row.items-top
                  q-breadcrumbs
                    q-btn(
                      icon="west",
                      flat,
                      dense,
                      :to="`/dashboard/${id}`",
                      v-if="type == 'meetings'"
                    )
                    q-breadcrumbs-el
                      q-select(:options="menu" :value="type" map-option map-options @input="navigate" dense borderless style="font-size:21px")
                    //- q-breadcrumbs-el.text-capitalize(
                    //-   :label="type",
                    //-   :disable="type == 'meetings'",
                    //-   :to="`/editor/${type}`"
                    //- )
                    q-breadcrumbs-el(
                      disable,
                      label="All Templates",
                      v-if="!id && type == 'templates'"
                    )
                    q-breadcrumbs-el(
                      disable,
                      label="All Styles",
                      v-if="!id && type == 'styles'"
                    )
                    q-breadcrumbs-el(disable, :label="id", v-if="id")
                .col-auto
                  q-select(
                    dense,
                    v-if="!id && type=='templates'",
                    v-model="selectedTag",
                    :options="tags",
                    clearable,
                    borderless,
                    style="min-width: 200px",
                    label="Filter by tag"
                  )
                    template(v-slot:selected-item="props")
                      q-badge(color="secondary") {{ props.opt }}
                //- .col-auto 
                //-   q-btn(
                //-     to="/editor/styles"
                //-     v-if="type=='templates' && !id"
                //-     flat,
                //-     label="Styles"
                //-   )  
                //-   q-btn(
                //-     to="/editor/templates"
                //-     v-if="type=='styles' && !id"
                //-     flat,
                //-     label="Templates"
                //-   )  
                q-btn(
                  flat,
                  @click="showPlugins = true",
                  v-if="id && (type==='templates' || type==='meetings')",
                  :label="$q.screen.gt.md ? 'Plugin Directory' : ''",
                  :icon="$q.screen.lt.lg ? 'folder' : ''"
                )
                VersionsDropdown(
                  :id="id",
                  :type="type"
                  v-on:compareVersion="compareVersion",
                  v-on:endCompare="endCompare"
                )

                q-btn(
                  flat,
                  color="primary",
                  :loading="saving",
                  @click="save",
                  :label="$q.screen.gt.md ? 'Save' : ''",
                  :icon="$q.screen.lt.lg ? 'save' : ''",
                  v-if="id && !diffOn"
                ) 

            MonacoEditor.col.editor(
              v-show="!((type=='templates' || type=='styles') && !id) && !diffOn",
              @editorWillMount="willMount",
              @editorDidMount="didMount",
              ref="editor",
              v-model="doc",
              :language="(type==='templates' || type==='meetings')?'yaml':'scss'",
              :options="options",
              :key="'yes'"
            )
            MonacoEditor.col.editor(
              ref="diff",
              v-show="diffOn",
              language="yaml",
              :options="options",
              :key="'diff'",
              :value="diffNew",
              :original="diffOriginal",
              :diffEditor="true",
              @change="diffUpdated"
            )
            .col(v-show="(type=='styles' && !id)")
              q-separator
              q-scroll-area.fit
                q-list(separator)
                  q-item(
                    v-for="(style,index) of styles",
                    clickable,
                    :key="style.id",
                    :to="`/editor/styles/${style.id}`"
                  )
                    q-item-section(header) {{ style.name }}
                      .text-grey-7.text-caption  {{ style.id }}
                    q-item-section(side)
                      q-btn(
                        icon="delete",
                        flat,
                        dense,
                        v-on:click.stop.prevent="removeStyle(style, index)"
                      )
                  .fixed-bottom-right.q-pa-md(
                    v-if="!id && type == 'styles'"
                  )
                    q-btn(
                      fab,
                      icon="add",
                      @click="addStyle()",
                      color="primary"
                    )
            .col(v-show="(type=='templates' && !id)")
              q-separator
              q-scroll-area.fit
                q-list(separator)
                  q-item(
                    v-for="(template,index) of filteredList",
                    clickable,
                    :key="template.id",
                    :to="`/editor/templates/${template.id}`"
                  )
                    q-item-section(header) {{ template.name }}
                      .text-grey-7.text-caption {{ template.program || 'default' }} &middot; {{ template.session || '?' }} &middot; {{ template.id }}
                    q-item-section(side)
                      div
                        q-badge.q-mr-xs(
                          color="secondary",
                          v-for="tag in template.tags",
                          :key="tag"
                        ) {{ tag.toUpperCase() }}
                    q-item-section(side)
                      q-btn(
                        icon="delete",
                        flat,
                        dense,
                        v-on:click.stop.prevent="remove(template, index)"
                      )
                  .fixed-bottom-right.q-pa-md(
                    v-if="!id && type == 'templates'"
                  )
                    q-btn(
                      fab,
                      icon="add",
                      @click="addTemplate()",
                      color="primary"
                    )
          q-resize-observer(@resize="onResize", ref="resizer")
        q-separator(vertical, v-if="id")
        .col-auto
          .column.fit(style="height: 100%")
            .col-auto.relative-position(style="width: 300px", v-if="id && type=='styles'").scroll
              v-style {{ outputcss.output }}
              q-banner(dense).bg-grey-5.text-white These style examples will update on [Save]
              .q-pa-sm
                .bicara-scoped-template
                  
                  //- .text-overline [Bordered Things]
                  .bordered.useclass(useclass="bordered" style="padding:5px;") This thing is bordered
                  //- .text-overline [Post-It]
                  .slides_plugin.useclass(style="position:relative;padding-top:20px;margin-bottom:40px;" useclass="slides_plugin")
                    .postit.useclass(useclass="postit" style="margin-top:30px;") This is a post-it

                  //- .text-overline [Markdown / Slide Content]
                  .slide-content.useclass.markdown.bordered(useclass="slide-content" style="padding-bottom:10px;padding-top:18px;")
                    h1.usetag(usetag="h1") Heading 1
                    h2.usetag(usetag="h2") Heading 2
                    h3.usetag(usetag="h3") Heading 3
                    h4.usetag(usetag="h4") Heading 4
                    p.usetag(usetag="p") This is body text
                    blockquote.usetag(usetag="blockquote")
                      p This is a quote
                    ul.usetag(usetag="ul")
                      li.usetag(usetag="li") List Item 1
                      li List Item 2
                    img.usetag(usetag="img" src="") 
                    //- .text-overline [Horizontal Line]
                    hr.usetag(usetag="hr" style="margin-bottom:20px;")
              
            .col-auto.relative-position(style="width: 500px", v-if="id && (type==='meetings' || type==='templates')")
              .absolute-bottom-right.z-max
                q-btn(
                  color="white",
                  flat,
                  icon="fullscreen",
                  type="a",
                  :href="`/#/display/${demoMeetingId}`",
                  target="__blank"
                )
              OverlayDisplay.scaled(
                style="height: 281px",
                :demo="true",
                :meetingid="demoMeetingId",
                :templateid="(type==='templates')?id:null"
                v-if="loaded"
              )
            .col-auto(style="width: 500px", v-if="id && (type==='templates'||type==='meetings')")
              q-list.fit
                q-item(dense)
                  q-item-section
                    q-item-label.text-bold.q-pl-none.text-primary() Current Script, using&nbsp;
                      router-link(:to="`/editor/styles/${style.name}`") {{ style.name }}
                      span &nbsp;style
                  q-item-section(side)
                    q-toggle(label="Auto Update", v-model="autoupdate")
                q-separator
                q-expansion-item.bg-grey-3(
                  expand-separator,
                  icon="public",
                  label="Global plugins are active in all parts of your script. Some have a visual element. Show them here."
                )
                  //- div {{ globalplugins.plugins }}
                  //- div {{ globalsenabled }}
                  q-toggle.text-capitalize(v-for="(plugin,key) of globalplugins.plugins" :val="key" :key="key" v-model="globalsenabled" :label="key")
                q-separator
                //- q-banner 
                q-expansion-item.bg-grey-3(
                  expand-separator,
                  icon="help_outline",
                  label="Script advance configuration is ignored here. Each script item is played on loop."
                )
                  .q-pa-sm Scripts can be advanced in one of three ways:
                    ul 
                      li 
                        div Triggered manually from the dashboard:
                        code
                          span.text-teal type:
                          span.text-blue-10.on-right manual
                      li 
                        div Automatically after a time interval:
                        code
                          span.text-teal type:
                          span.text-blue-10.on-right timed
                          br
                          span.text-teal when:
                          span.text-blue-10.on-right 5 minutes
                            a(
                              href="https://github.com/agenda/human-interval",
                              target="_blank"
                            )
                              q-icon.on-right.q-mb-xs(name="help")

                      li 
                        div Trigged by a plugin according to internal plugin logic:
                        code
                          span.text-teal type:
                          span.text-blue-10.on-right auto

            .col(v-if="type=='templates'")
              q-item.bg-negative.text-white(v-if="error" style="width: 500px") {{ error }}
              q-scroll-area.fit
                div(v-if="error == false && json.schedule")
                  div(v-for="(item, key) of json.schedule.script")
                    q-item(clickable, @click="selectScript(key)")
                      q-item-section(avatar)
                        q-avatar(color="secondary", text-color="white") {{ key + 1 }}
                      q-item-section
                        q-item-label {{ item.name }}
                      q-item-section(side, v-if="currentScriptItem == key")
                        q-icon(name="lens", color="red")

  q-dialog(v-model="showPlugins")
    q-card(style="min-width: 600px")
      q-card-section
        .text-h6 Available Plugins
      q-separator
      q-card-section.q-pa-none
        PluginList(v-on:insert="insertPlugin")
      q-separator
      q-card-actions(align="right")
        q-btn(flat, label="OK", color="primary", v-close-popup)
</template>

<script>
import { db, auth, functions, saveStyle, getStyleForMeeting } from "@/db";
import MonacoEditor from "vue-monaco";

const yaml = require("js-yaml");
import PluginList from "./PluginList.vue";
import {
  keyBy,
  mapValues,
  merge,
  includes,
  map,
  uniq,
  flatten,
  compact,
  filter,
} from "lodash";
import MarkdownDialog from "./MarkdownDialog.vue";
import { debounce } from "quasar";
// import moment from "moment-timezone";
import VersionsDropdown from "./VersionsDropdown.vue";

let _monaco = null;
// let hasCodeLens = false;

// let pluginRegex = /- plugin: (.*)/;
// let advanceRegex = /advance:/;
let contentRegex = /content:/;
import notifications from "../../utils/notifications";
// let contentElementRegex = /^.*-.*$/;

export default {
  name: "Editor",
  mixins: [notifications],
  props: ["id", "type"],
  async created() {
    this.updateMarkdown = debounce(this.updateMarkdown, 300, false);
    this.selectScript = debounce(this.selectScriptOriginal, 300, false);
  },
  data: () => {
    return {
      menu: [
        { label: "Templates", value: "templates" },
        { label: "Styles", value: "styles" },
      ],
      style: {},
      loaded: false,
      selectedTag: null,
      diffOn: false,
      diffNew: "",
      diffOriginal: "",
      remount: 0,
      persistentFields: {},
      showPlugins: false,
      autoupdate: true,
      uuid: "",
      doc: "",
      error: false,
      currentpath: "/",
      saving: false,
      editpanel: {},
      editplugin: {},
      currentScriptItem: -1,
      userid: null,
      outputcss: "",
      json: {
        script: [],
      },
      options: {
        enableSplitViewResizing: false,
        //     // Render the diff inline
        // renderSideBySide: false,
        // automaticLayout: true,
        wordWrap: "wordWrapColumn",
        fontSize: 16,
        lineNumbers: "off",
        tabSize: 2,
        detectIndentation: false,
        minimap: {
          enabled: false,
        },
      },
      templates: [],
      styles: [],
      globalplugins: { plugins: {} },
      globalsenabled: [],
    };
  },
  computed: {
    tags() {
      const alltags = map(compact(flatten(map(this.templates, "tags"))), (f) =>
        f.toUpperCase()
      );
      return uniq(alltags);
    },
    fullName() {
      return this.json.name;
    },
    objectpath() {
      return [this.type, `${this.id}`];
    },
    demoMeetingId() {
      return `demo_${auth.currentUser.uid}_${this.id}`;
    },
    filteredList() {
      if (this.selectedTag === null) return this.templates;
      return filter(this.templates, (f) => {
        return includes(
          map(f.tags, (f) => f.toUpperCase()),
          this.selectedTag
        );
      });
    },
  },
  methods: {
    navigate(val) {
      // console.log(val);
      this.$router.replace(`/editor/${val.value}/`);
    },
    remove(template, index) {
      // console.log(template.id);
      this.$q
        .dialog({
          title: "Remove template",
          message: "Remove this template?",
          cancel: true,
          persistent: true,
        })
        .onOk(async (data) => {
          try {
            this.$q.loading.show();
            //run version save:
            // console.log("removed");
            await db.ref(`templates/${template.id}`).remove();
            // const index = find(this.templates, template.id);
            this.templates.splice(index, 1);
            this.$q.notify({
              type: "positive",
              message: `Template removed!`,
            });
          } catch (e) {
            this.showError(e);
          } finally {
            // this.loading = false;
            this.$q.loading.hide();
          }
        });
    },
    diffUpdated(newval) {
      // console.log(newval);
      this.doc = newval;
    },
    compareVersion(content) {
      // console.log(content);
      this.diffOn = true;
      this.diffOriginal = content;
      this.diffNew = this.doc;

      // this.$refs.resize();
    },
    endCompare() {
      this.diffOn = false;
    },
    updateMarkdown(obj, line) {
      var range = new _monaco.Range(line + 1, 1, line + 1, 10000);
      var id = { major: 1, minor: 1 };
      var op = {
        identifier: id,
        range: range,
        text: obj,
        // forceMoveMarkers: true,
      };
      this.$refs.editor.getEditor().executeEdits("markdown-update", [op]);
    },
    async updateSlidesIndex(index) {
      this.persistentFields = {
        slides: {
          currentslide: index,
        },
      };
      // this.selectScript(this.currentScriptItem);
    },
    async openMarkdownEditor(line, text, index, segment) {
      this.$q
        .dialog({
          component: MarkdownDialog,
          content: text,
          meetingid: this.demoMeetingId,
          line: line,
          updateFn: this.updateMarkdown,
          library: this.library,
        })
        .onCancel(() => {
          //reset back to original if no changes:
          this.updateMarkdown(text, line);
        });

      // console.log(`segment: ${segment}, index: ${index}`);

      this.selectScript(segment);
      this.updateSlidesIndex(index);
    },
    addTemplate() {
      this.$q
        .dialog({
          title: "New Template",
          message: "Enter Template Name",
          prompt: {
            model: "",
            type: "text", // optional
          },
          cancel: true,
          persistent: true,
        })
        .onOk(async (data) => {
          //do something with data:
          let slug = data
            .replaceAll(" ", "")
            .replaceAll(".", "")
            .replaceAll("#", "")
            .replaceAll("$", "")
            .replaceAll("[", "")
            .replaceAll("]", "")
            .toLowerCase();

          //add new template:
          await db.ref(`/templates/${slug}`).set({
            name: data,
            raw: yaml.dump({
              name: data,
              schedule: {
                global: [],
                script: [],
              },
            }),
          });

          //route to new template:
          this.$router.push(`/editor/templates/${slug}`);
        });
    },
    addStyle() {
      this.$q
        .dialog({
          title: "New Style",
          message: "Enter Style Name",
          prompt: {
            model: "",
            type: "text", // optional
          },
          cancel: true,
          persistent: true,
        })
        .onOk(async (data) => {
          //do something with data:
          let slug = data
            .replaceAll(" ", "")
            .replaceAll(".", "")
            .replaceAll("#", "")
            .replaceAll("$", "")
            .replaceAll("[", "")
            .replaceAll("]", "")
            .toLowerCase();

          //add new template:
          await db.ref(`/styles/${slug}`).set({
            name: data,
            css: "//Write CSS or SCSS here",
          });

          //route to new template:
          this.$router.push(`/editor/styles/${slug}`);
        });
    },
    onResize(params) {
      // console.log(params);
      if (this.$refs.editor && this.$refs.editor.getEditor())
        this.$refs.editor.getEditor().layout(params);

      if (this.$refs.diff && this.$refs.diff.getEditor())
        this.$refs.diff.getEditor().layout(params);
    },
    async selectScriptOriginal(index) {
      console.log(`set: ${index}`);
      if (index >= 0) {
        this.currentScriptItem = index;

        // console.log(this.json);
        if (this.json) {
          try {
            let trans =
              this.json.schedule.script[this.currentScriptItem].plugins;

            let currentState = {
              plugins: mapValues(
                keyBy(trans, (o) => o.plugin.toLowerCase()),
                "settings"
              ),
            };

            // console.log(currentState);

            for (const key in currentState.plugins) {
              if (!currentState.plugins[key]) currentState.plugins[key] = {};
              currentState.plugins[key].enabled = true;

              currentState.plugins[key] = merge(
                this.persistentFields[key],
                currentState.plugins[key]
              );
            }

            // trans = this.json.schedule.global;

            // let globalplugs = {
            //   plugins: mapValues(
            //     keyBy(trans, (o) => o.plugin.toLowerCase()),
            //     "settings"
            //   ),
            // };

            // this.globalplugins.plugins = { ...globalplugs.plugins };

            // let globalplugs = this.globalplugins;

            // console.log(this.globalplugins.plugins);

            let globalstoadd = {};
            // //for global plugins:
            for (const key in this.globalplugins.plugins) {
              //check if editor enabled it
              if (includes(this.globalsenabled, key)) {
                globalstoadd[key] = {
                  global: true,
                  enabled: true,
                };
                // if (!globalplugs[key]) globalplugs[key] = {};
                // globalplugs[key].global = true;
                // globalplugs[key].enabled = true;
              }
              //check if its enabled:
              // } else delete globalplugs[key];
            }

            // console.log(globalstoadd);

            currentState.plugins = merge(currentState.plugins, globalstoadd);

            // console.log("SELECTING ***");

            await db
              .ref(`config/${this.demoMeetingId}/uid`)
              .set(auth.currentUser.uid);

            // console.log(auth.currentUser.uid);

            await db
              .ref(
                `config/${this.demoMeetingId}/current/currentState/demoSection`
              )
              .set(index);

            await db
              .ref(`config/${this.demoMeetingId}/current/currentState/plugins`)
              .set(currentState.plugins);
          } catch (e) {
            console.error(e);
            console.log("cant update display", e);
          }
        } else return {};
      }
    },
    parseYaml(text) {
      try {
        // console.log("parsing");
        this.error = false;
        let obj = yaml.load(text);
        return obj;
      } catch (e) {
        this.error = e;
        return {
          script: [],
        };
      }
    },
    insertPlugin(plugin) {
      // console.log(Object.keys(plugin.settings));
      var insertable = {
        plugin: plugin.plugin,
        settings: {
          // enabled: true,
          ...plugin.settings,
        },
      };

      var obj = yaml.dump(insertable);

      var line = this.$refs.editor.getEditor().getPosition();
      var range = new _monaco.Range(line.lineNumber, 1, line.lineNumber, 1);
      var id = { major: 1, minor: 1 };
      var op = {
        identifier: id,
        range: range,
        text: obj,
        forceMoveMarkers: true,
      };
      this.$refs.editor.getEditor().executeEdits("plugin-insert", [op]);
      //activeTextEditor.insertSnippet(snippet, insertionLocation)
    },
    didMount(editor) {
      //ADD COMMANDS
      const markdownCommand = editor.addCommand(
        -1,
        (param, line, text, index) => {
          //go back up through file to find the nearest 'name' field.

          //get the index of this name field in the doc, and then set the currentSection to be that.

          let found = false;
          let lines = line;
          const _doc = this.doc;
          let splitDoc = _doc.split("\n");
          while (!found && lines > 0) {
            if (includes(splitDoc[lines], "- name: ")) {
              found = splitDoc[lines].trim().replace("- name: ", "");
            }
            lines--;
          }

          // console.log(`found: ${found}`);
          let keys = map(Object.values(this.json.schedule.script), "name");
          // console.log(keys);

          let ii = keys.indexOf(found);

          // console.log(`index: ${ii}`);

          this.openMarkdownEditor(line, text, index, ii);
        }
      );
      // console.log("remount", this.remount);
      if (this.remount > 0) return;
      this.remount++;
      _monaco.languages.registerCodeLensProvider("yaml", {
        resolveCodeLens: function (model, codeLens) {
          return codeLens;
        },
        provideCodeLenses: () => {
          const _doc = this.doc;
          //loop through each line:
          let lenses = [];
          let index = 1;
          // let advancecounter = 0;
          let splitDoc = _doc.split("\n");
          for (const line of splitDoc) {
            if (contentRegex.test(line)) {
              //check all subsequent lines:
              let startAt = index;
              let ismore = true;
              let spaces = line.match(/^\s*/)[0];
              let ii = 0;
              // console.log(spaces);
              while (ismore) {
                let nextline = splitDoc[startAt];

                if (
                  nextline &&
                  nextline.includes("- ") &&
                  nextline.startsWith(spaces + "  ")
                ) {
                  // console.log(splitDoc[startAt]);

                  lenses.push({
                    range: {
                      startLineNumber: startAt + 1,
                      startColumn: 1,
                      endLineNumber: startAt + 1,
                      endColumn: 1,
                    },
                    id: "MarkdownEdit",
                    command: {
                      arguments: [startAt, splitDoc[startAt], ii],
                      id: markdownCommand,
                      title: "Edit",
                    },
                  });

                  startAt++;
                  ii++;
                } else ismore = false;
              }
            }
            index++;
          }

          return {
            lenses: lenses,
            dispose: () => {},
          };
        },
      });
    },

    willMount(monaco) {
      _monaco = monaco;
    },
    async save() {
      try {
        this.saving = true;

        if (this.type === "templates" || this.type === "meetings") {
          let obj = yaml.load(this.doc);

          obj.raw = this.doc.toString();
          console.log("saving");

          if (this.type === "templates") {
            await db.ref(`/templates/${this.id}`).set(obj);
          } else {
            await db.ref(`/config/${this.id}/schedule`).set(obj);
          }
        } else {
          //obj
          console.log(this.id);

          // this.styles[this.id].css = this.doc.toString();
          // await db.ref(`/styles/${this.id}`).update({ css: this.doc });

          await saveStyle({ id: this.id, css: this.doc });
        }
      } catch (e) {
        this.$q.notify({
          type: "negative",
          message: `${e}`,
        });
      } finally {
        this.saving = false;
      }
    },
  },
  async mounted() {
    // console.log("m");
    // this.$q.loading.show({});
    // await this.getUser();
    // this.remount++;
  },
  components: { MonacoEditor, PluginList, VersionsDropdown },
  watch: {
    globalsenabled: {
      handler() {
        // console.log("plugin changed");
        if (this.autoupdate) {
          try {
            if (this.json.schedule.script.length > 0)
              this.selectScript(this.currentScriptItem);
            else this.selectScript(-1);
          } catch (e) {
            //cant parse doc
            console.error(e);
            console.log("cant parse");
          }
        }
      },
    },
    doc: {
      handler() {
        if (this.type === "meetings" || this.type === "templates")
          if (this.doc) {
            console.log("updating");
            this.json = this.parseYaml(this.doc);

            let globalplugs = {
              plugins: mapValues(
                keyBy(this.json.schedule.global, (o) => o.plugin.toLowerCase()),
                "settings"
              ),
            };

            this.globalplugins.plugins = globalplugs.plugins;

            if (this.autoupdate) {
              try {
                if (this.json.schedule.script.length > 0)
                  this.selectScript(this.currentScriptItem);
                else this.selectScript(-1);
              } catch (e) {
                //cant parse doc
                console.error(e);
                console.log("cant parse");
              }
            }
          } else {
            this.json = {
              schedule: {
                script: [],
              },
            };
          }
      },
    },
    id: {
      immediate: true,
      async handler() {
        // console.log(`id: ${this.id}`);
        if (this.id && this.type == "styles") {
          this.$q.loading.show();
          try {
            this.doc = (
              await db.ref(`/styles/${this.id}`).once("value")
            ).val().css;

            this.$rtdbBind("outputcss", db.ref(`/styles/${this.id}`));

            this.loaded = true;
          } catch (e) {
            this.showError(e);
          } finally {
            this.$q.loading.hide();
          }
        } else if (this.id && this.type == "templates") {
          this.$q.loading.show();
          // console.log("show");
          // load template:
          try {
            let obj = null;
            if (this.type === "templates") {
              // console.log("templates");
              obj = (await db.ref(`/templates/${this.id}`).once("value")).val();
              this.style = (
                await getStyleForMeeting({ templateid: this.id })
              ).data;
              if (!obj) {
                obj = {
                  name: "Default Template",
                  schedule: {
                    global: [],
                    script: [],
                  },
                };
              }

              if (obj.raw) this.doc = obj.raw;
              else this.doc = yaml.dump(obj);

              //if the demo config nodes has not been created - create it!
              try {
                console.log(`Attempting to bind to ${this.demoMeetingId}`);
                const demonode = await db
                  .ref(
                    `/config/${this.demoMeetingId}/current/currentState/demoSection`
                  )
                  .once("value");
              } catch {
                // console.log(`demonode needs creating`);
                this.currentScriptItem = 0;
                this.selectScriptOriginal(this.currentScriptItem);
              } finally {
                this.loaded = true;
              }
            } else {
              this.style = (
                await getStyleForMeeting({ meetingid: this.id })
              ).data;
              // console.log("binding directly...");
              obj = (
                await db.ref(`/config/${this.id}/schedule`).once("value")
              ).val();

              if (!obj) {
                obj = {
                  name: "Default Template",
                  schedule: {
                    global: [],
                    script: [],
                  },
                };
              }

              if (obj.raw) this.doc = obj.raw;
              else this.doc = yaml.dump(obj);
              console.log(`cs: ${this.currentScriptItem}`);
            }

            this.currentScriptItem = (
              await db
                .ref(
                  `/config/${this.demoMeetingId}/current/currentState/demoSection`
                )
                .once("value")
            ).val();

            this.$q.loading.hide();
          } catch (e) {
            console.error(e);
            this.$q.loading.hide();
            // this.$router.back();
          }
        } else {
          this.$q.loading.show();
          this.templates = Object.values(
            (await functions.httpsCallable("templates-listTemplates")({})).data
          );

          this.styles = Object.values(
            (await functions.httpsCallable("templates-listStyles")({})).data
          );

          this.$q.loading.hide();
        }
      },
    },
  },
};
</script>

<style lang="scss">
.editor {
  overflow: hidden;
}

.scaled {
  transform: scale(0.39);
  transform-origin: 0 0;
}
</style>

import { render, staticRenderFns } from "./PluginList.vue?vue&type=template&id=7fd88c65&lang=pug&"
import script from "./PluginList.vue?vue&type=script&lang=js&"
export * from "./PluginList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QList,QItem,QSpinner,QExpansionItem,QItemSection,QAvatar,QItemLabel,QCard,QCardSection,QSeparator,QBadge});

<template lang="pug">
q-list(separator)
  q-item.items-center.justify-center(v-if="loading")
    q-spinner(size="sm")
  q-item.q-pa-none(v-for="(plugin, index) in plugins", :key="index")
    q-expansion-item.full-width.q-pa-none(group="plugins")
      template(v-slot:header)
        q-item-section(avatar)
          q-avatar(color="primary", text-color="white", :icon="plugin.icon")
        q-item-section
          q-item-label {{ plugin.displayname }}
          q-item-label(caption) By {{ plugin.author }} &middot; {{ plugin.branch }}
        q-item-section(side)
          q-item-label(caption)
      q-card
        q-card-section
          .text-body1 {{ plugin.description || `${plugin.displayname} plugin.` }}
          q-separator.q-my-lg
          code
            span.text-teal plugin:
            span.text-green &nbsp;{{ plugin.displayname }}
            div(v-if="plugin.settings")
              .text-teal settings:
              div(v-for="(setting,key) of plugin.settings")
                .text-green.q-ml-md # {{ setting.desc }}
                .text-teal.q-ml-md {{ key }}:
                  span.text-grey-6 &nbsp;{{ setting.default || getDefault(setting.type) }}
                  q-badge.on-right.float-right {{ setting.type }}
                  q-badge.on-right.float-right(
                    color="red",
                    v-if="setting.required"
                  ) Required
</template>

<script>
// const BASE_URL =
//   "https://action-lab-aus.gitlab.io/zoomsense/zoomsense-plugin-directory/";

export default {
  name: "PluginList",
  data: () => {
    return {
      plugins: [],
      loading: true,
    };
  },
  methods: {
    getDefault(type) {
      switch (type) {
        case "String":
          return '""';
        case "Boolean":
          return true;
        case "Float":
          return 1.0;
      }
    },
    letter(plugin) {
      return plugin.displayname.substr(0, 1);
    },
    clicked(plugin) {
      if (!plugin.settings) {
        plugin.settings = {};
      }

      plugin.settings.enabled = {
        type: typeof true,
        default: true,
        required: true,
      };
      this.$emit("insert", plugin);
    },
  },
  async created() {
    let resp = await fetch(`${process.env.VUE_APP_PLUGIN_DIRECTORY}index.json`);
    this.plugins = await resp.json();
    this.loading = false;
  },
};
</script>

<style lang="scss">
</style>


<template lang="pug">
q-dialog(ref="dialog", @hide="onDialogHide")
  q-card(style="min-width: 90vw; min-height: 90vh")
    .row
      //- q-card-section.full-height.bg-red(style="min-height: 50vh")
      //- div {{ height }}
      .col-8
        .column.fit
          .col-auto
            q-resize-observer(@resize="onResize")
            OverlayDisplay.bigscaled(
              :style="{ transform: `scale(${scale})` }",
              :demo="true",
              :meetingid="meetingid"
            )
      q-separator(vertical)
      .col
        Editor(
          :initialValue="initContent",
          :options="editoroptions",
          initialEditType="markdown",
          :previewStyle="'tab'",
          height="85vh",
          ref="toastuiEditor",
          @change="update()"
        )

    q-separator

    q-card-actions(align="right")
      q-btn(flat, color="primary", label="Cancel", @click="onCancelClick")
      q-btn(flat, color="primary", label="OK", @click="onOKClick")
</template>

<script>
import { Editor } from "@toast-ui/vue-editor";
import "@toast-ui/editor/dist/toastui-editor.css";
import OverlayDisplay from "@/views/OverlayDisplay";

// const reWidgetRule = /::: .*:.*/;

export default {
  name: "MarkdownEditor",
  props: ["content", "meetingid", "line", "updateFn"],
  components: { Editor, OverlayDisplay },
  data: () => {
    return {
      height: 100,
      width: 100,
      updated: "",
    };
  },
  computed: {
    editoroptions() {
      return {
        usageStatistics: false,
        // previewStyle: "tab",
        // minHeight: `${this.height}px`,
        // maxHeight: `${this.height}px`,
        // maxHeight: "600px",
        hideModeSwitch: true,
        customHTMLRenderer: {
          paragraph(node, { origin }) {
            // const regexp = /(<%.*%>)|({:toc.*})/;
            const regexp = /^:::\s(s-)?(\w*):.*$/;
            const result = origin();
            const { firstChild } = node;

            if (regexp.test(firstChild.literal)) {
              const { attributes = {} } = result;
              attributes.contenteditable = false;
              attributes.class = "zs-input-field";
              result.attributes = attributes;
            }

            return result;
          },
        },
      };
    },
    heightH() {
      return this.height;
    },
    initContent() {
      // console.log(this.content.replace(/\w*- /, ""));
      return this.content
        .replace(/\s*-\s"?/, "")
        .replace(/"$/, "")
        .replaceAll("\\n", "\n");
    },
    scale() {
      return this.width / 1280;
    },
  },
  methods: {
    convert(txt) {
      let res = txt.replaceAll("\n", "\\n");

      let spaces = this.content.match(/^\s*/);

      return `${spaces[0]}- "${res}"`;
    },
    update() {
      let obj = this.$refs.toastuiEditor.invoke("getMarkdown");
      // console.log(obj);
      this.updateFn(this.convert(obj), this.line);
      // this.$emit("update", obj);
    },
    onResize(size) {
      if (size.height > 0) this.height = size.height;
      if (size.width > 0) this.width = size.width;
    },
    // following method is REQUIRED
    // (don't change its name --> "show")
    show() {
      this.$refs.dialog.show();
    },

    // following method is REQUIRED
    // (don't change its name --> "hide")
    hide() {
      this.$refs.dialog.hide();
    },

    onDialogHide() {
      // required to be emitted
      // when QDialog emits "hide" event
      this.$emit("hide");
    },

    onOKClick() {
      // on OK, it is REQUIRED to
      // emit "ok" event (with optional payload)
      // before hiding the QDialog
      let cont = this.$refs.toastuiEditor.invoke("getMarkdown");

      this.$emit("ok", { content: this.convert(cont) });
      // or with payload: this.$emit('ok', { ... })

      // then hiding dialog
      this.hide();
    },

    onCancelClick() {
      //reset:
      // this.updateFn(this.content, this.line);
      // we just need to hide dialog
      this.hide();
    },
  },
};
</script>

<style lang="scss">
.bigscaled {
  // transform: scale(0.6);
  transform-origin: top left;
}

.zs-input-field {
  border: silver 2px dashed;
  border-radius: 5px;
  margin: 3px;
  padding: 3px;
  .demo-type {
    text-transform: uppercase;
    font-size: 12px;
    display: block;
    margin: 0;
    padding: 0;
    line-height: 10px;
    color: silver;
  }
  .demo-name {
    display: block;
    line-height: 22px;
    font-size: 20px;
    margin: 0;
    padding: 0;
  }
}

.toastui-editor-defaultUI {
  border: none !important;
  // border-radius: 0 !important;
  // border-left: 1px solid silver !important;
}
</style>


<template lang="pug">
div
  q-btn(v-if="!id", flat, @click="createVersion") Create Snapshot
  .row.items-center(v-if="comparing && id")
    q-chip(removable, @remove="endCompare")
      .text-primary Comparing with {{ getFrom(currentCompare.when) }}
  q-btn-dropdown(
    v-if="!comparing && id",
    :loading="loading",
    :label="$q.screen.gt.md ? 'Compare' : ''",
    :icon="$q.screen.lt.lg ? 'compare' : ''",
    flat,
    ,
    @show="getVersions"
  )
    q-list(separator, dense, style="min-width: 150px")
      q-item.justify-center.items-center(v-if="loadingVersions && id")
        q-spinner
      q-item(
        v-if="id",
        clickable,
        v-close-popup,
        :key="ver.when",
        @click="compareVersion(ver)",
        v-for="ver in versions"
      )
        q-item-section.q-py-xs
          q-item-label {{ ver.what }}
          q-item-label.text-grey.text-caption(subtitle) {{ getFrom(ver.when) }} &middot; {{ getTime(ver.when) }}
</template>

<script>
import { functions } from "@/db";
import moment from "moment-timezone";
import { orderBy } from "lodash";

export default {
  props: ["id", "type"],
  data() {
    return {
      loadingVersions: true,
      versions: [],
      loading: false,
      comparing: false,
    };
  },
  methods: {
    endCompare() {
      this.comparing = false;
      this.$emit("endCompare");
    },
    async compareVersion(version) {
      try {
        //run version save:
        this.loading = true;
        // console.log(this.id);
        let result = await functions.httpsCallable("git-getVersion")({
          tag: version.when,
        });

        // console.log("Trying compare");

        // console.log(result.data[this.id]);
        this.currentCompare = version;
        if (this.type === "styles")
          this.$emit("compareVersion", result.data.styles[this.id].raw);
        else this.$emit("compareVersion", result.data.templates[this.id].raw);
        this.comparing = true;
      } catch (e) {
        // console.error("no compare possible");
        // console.log(e);
        this.$q.notify({
          type: "negative",
          message: `This file cannot be compared (there might not be a previous version)`,
        });
      } finally {
        this.loading = false;
      }
      // console.log(version);
    },
    async createVersion() {
      //open dialog to get a commment
      this.$q
        .dialog({
          title: "Name your new snapshot",
          message:
            "Creating a snapshot captures the current state of all templates and styles. Each template or style can then be compared individually to this snapshot in time, at a later date.",
          prompt: {
            model: "",
            isValid: (val) => val.length > 2,
          },
          cancel: true,
          persistent: true,
        })
        .onOk(async (data) => {
          try {
            this.$q.loading.show();
            //run version save:
            await functions.httpsCallable("git-saveVersion")({
              message: data,
            });
            this.$q.notify({
              type: "positive",
              message: `New version created!`,
            });
          } catch (e) {
            this.$q.notify({
              type: "negative",
              message: `${e}`,
            });
          } finally {
            // this.loading = false;
            this.$q.loading.hide();
          }
        });
    },
    getFrom(time) {
      return moment.unix(time / 1000).fromNow();
    },
    getTime(time) {
      return moment.unix(time / 1000).format("LT LL");
    },
    async getVersions() {
      try {
        this.versions = (
          await functions.httpsCallable("git-getVersions")({})
        ).data;

        this.versions = orderBy(this.versions, "when", "desc");

        this.loadingVersions = false;
      } catch (e) {
        this.$q.notify({
          type: "negative",
          message: `${e}`,
        });
      }
    },
  },
};
</script>
